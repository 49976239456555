<template>
  <div v-padding:top="46">
    <van-nav-bar title="站点信息申报" fixed left-arrow @click-left="goBack">
      <template #right>
        <van-icon name="add-o" color="#1989fa" size="20" @click="addList" />
      </template>
    </van-nav-bar>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多数据了"
      @load="getInformationReviewList"
    >
      <event-panel
        v-for="(item, index) in monitorinspectedList"
        @click="onCellClick(item)"
        :key="index"
        :index="index + 1"
        :title="item.relatedName"
        :enterprise="''"
        :point="item.gmtCreate"
      >
        <van-tag
          slot="extra"
          plain
          :type="
            item.status == '1'
              ? 'primary'
              : item.status == '2'
              ? 'success'
              : 'danger'
          "
          size="small"
          style="margin-right: 10px"
          v-if="[1, 2, 3].includes(item.status)"
        >
          {{
            item.status == "1"
              ? "审核中"
              : item.status == "2"
              ? "审核通过"
              : "审核不通过"
          }}
        </van-tag>
      </event-panel>
    </van-list>
  </div>
</template>

<script>
import eventPanel from "@/components/common/event-panel.vue"; // 通用面板

export default {
  components: { eventPanel },
  props: {},
  data() {
    return {
      enterpriseId: localStorage.getItem("enterpriseId"),
      formData: {
        deviceName: "",
      },
      loading: false, // 上拉加载loading
      finished: false, // 上拉加载完结loading
      current: 1, // 页码
      pagesize: 10, // 单页条数
      monitorinspectedList: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    // 获取许可证列表
    getInformationReviewList() {
      const data = {
        index: this.current,
        size: this.pagesize,
        enterpriseId: this.enterpriseId,
        type: 3,
      };
      this.$api.setting
        .getInformationReviewListPage(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.monitorinspectedList.push(...res.data.data.records);
            if (this.current >= res.data.data.pages) {
              this.finished = true;
            } else {
              this.current++;
            }
          }
        })
        .catch((err) => {
          this.finished = true;
          throw err;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 单元格点击
    onCellClick(item) {
      this.$router.push({
        path: "setting-point-edit",
        query: {
          operate: "edit",
          id: item.id,
        },
      });
    },
    // 新增
    addList() {
      const data = {
        type: 3,
        relatedId: this.enterpriseId,
      };
      this.$api.setting.getInformationRecognition(data).then((res) => {
        if (res.data.state == 0) {
          this.$router.push({
            path: "setting-point-edit",
            query: {
              operate: "add",
              id: this.enterpriseId,
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
